module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography.js"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Alexandru-Dan Pop - JavaScript FullStack developer","short_name":"Alex","description":"Freelance FullStack JavaScript developer for hire, specializing in the development of custom web applications in Typescript, React.js, Next.js, Gatsby, .Net Core, & more.","lang":"en","start_url":"/","background_color":"#000","theme_color":"#fff","display":"standalone","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"17d0de7c5640352fdfedbfb7118dc845"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"layout":"/opt/build/repo/@christiandavid/gatsby-theme-byfolio/src/layout/index.js"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"showSpinner":false},
    },{
      plugin: require('../../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-2X1YWGET59","head":false,"anonymize":true,"send_page_view":true},
    }]
