// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---christiandavid-gatsby-theme-byfolio-src-pages-404-js": () => import("./../../../../@christiandavid/gatsby-theme-byfolio/src/pages/404.js" /* webpackChunkName: "component---christiandavid-gatsby-theme-byfolio-src-pages-404-js" */),
  "component---christiandavid-gatsby-theme-byfolio-src-pages-about-me-js": () => import("./../../../../@christiandavid/gatsby-theme-byfolio/src/pages/about-me.js" /* webpackChunkName: "component---christiandavid-gatsby-theme-byfolio-src-pages-about-me-js" */),
  "component---christiandavid-gatsby-theme-byfolio-src-pages-experience-js": () => import("./../../../../@christiandavid/gatsby-theme-byfolio/src/pages/experience.js" /* webpackChunkName: "component---christiandavid-gatsby-theme-byfolio-src-pages-experience-js" */),
  "component---christiandavid-gatsby-theme-byfolio-src-pages-index-js": () => import("./../../../../@christiandavid/gatsby-theme-byfolio/src/pages/index.js" /* webpackChunkName: "component---christiandavid-gatsby-theme-byfolio-src-pages-index-js" */),
  "component---christiandavid-gatsby-theme-byfolio-src-pages-skills-js": () => import("./../../../../@christiandavid/gatsby-theme-byfolio/src/pages/skills.js" /* webpackChunkName: "component---christiandavid-gatsby-theme-byfolio-src-pages-skills-js" */),
  "component---christiandavid-gatsby-theme-byfolio-src-templates-job-js": () => import("./../../../../@christiandavid/gatsby-theme-byfolio/src/templates/job.js" /* webpackChunkName: "component---christiandavid-gatsby-theme-byfolio-src-templates-job-js" */)
}

